import { classNames } from '~/lib';
import { Heading, Button } from '.';

interface HeaderCtaProps {
  titleVariant?: 'h1' | 'h2';
  buttonLink?: string;
  content?: any;
  className?: string;
}

export const HeaderCta = ({
  content,
  titleVariant = 'h1',
  buttonLink,
  className,
}: HeaderCtaProps) => {
  const buttonText = content?.button_text || content?.button;

  return (
    <div className={classNames('max-w-6xl px-4 mx-auto w-full', className)}>
      {!!content?.kicker && (
        <Heading as="h6" variant="h6" className="mb-2 !capitalize !text-base">
          {content?.kicker}
        </Heading>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-20">
        {!!content?.title && (
          <Heading
            as="h1"
            variant={titleVariant}
            className="!font-medium leading-[110%]"
          >
            {content?.title}
          </Heading>
        )}
        <div>
          {!!content?.description && (
            <p className="text-xl leading-normal text-grey-700 mt-4 lg:mt-0">
              {content?.description}
            </p>
          )}

          {!!buttonText && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                document
                  .querySelector(buttonLink || `#${content?.button_link}`)
                  ?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <Button
                variant="secondary"
                size="large"
                className="mt-8 max-w-max shadow-dark"
              >
                {buttonText}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
