import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { Heading } from '../Heading';

export const TestimonialSection = ({ content }) => {
  return (
    <div className="w-full py-16 md:py-20">
      <div className="max-w-6xl px-4 mx-auto">
        {!!content?.title && (
          <Heading as="h3" variant="h3" className="!font-medium">
            {content?.title}
          </Heading>
        )}
        <div className="w-full flex flex-col md:flex-row mt-10 md:mt-16 space-y-12 md:space-y-0 md:space-x-10 lg:space-x-32">
          {!!content?.image?.filename && (
            <div className="w-full flex-shrink-0 max-w-[200px] md:max-w-xs rounded-2xl overflow-hidden">
              <Image
                alt={content?.image?.alt || ''}
                src={content?.image?.filename}
                fluid={400}
                fit="contain"
                showPlaceholder
                width="100%"
                className={classNames('rounded-2xl')}
              />
            </div>
          )}
          <div>
            {!!content?.logo?.filename && (
              <div className="max-w-[120px] w-full mb-8 md:mb-10">
                <Image
                  alt={content?.logo?.alt || ''}
                  src={content?.logo?.filename}
                  fluid={120}
                  fit="contain"
                  showPlaceholder
                  width="100%"
                />
              </div>
            )}
            {!!content?.quote && (
              <p className="text-2xl md:text-3xl leading-[130%]">
                {content?.quote}
              </p>
            )}
            {!!content?.author && (
              <p className="font-medium text-lg mt-8">— {content?.author}</p>
            )}
            {!!content?.subtext && (
              <p className="text-gray-600">{content?.subtext}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
