import { Container, Heading, RichText } from '..';

interface StatisticsContentSectionProps {
  content?: any;
}

export const StatisticsContentSection = ({
  content,
}: StatisticsContentSectionProps) => {
  return (
    <div className="flex flex-col items-center mb-24">
      <div className="bg-white bg-gradient-to-t from-yellow-50 w-full max-w-xs md:max-w-3xl lg:max-w-5xl rounded-2xl border border-yellow-400 flex flex-col md:flex-row items-center z-10">
        {content?.stats?.map((stat) => (
          <div
            key={stat?._uid}
            className="w-full md:w-1/3 py-10 flex flex-col items-center justify-center border-t md:border-l md:border-t-0 first:border-t-0 first:border-l-0 border-yellow-400"
          >
            <Heading variant="h2" as="h5" className="text-center">
              {stat?.content}
            </Heading>
            <div className="text-center md:text-lg">{stat?.description}</div>
          </div>
        ))}
      </div>
      <div className="bg-yellow-100 bg-opacity-50 bg-gradient-to-b from-yellow-100 w-full -mt-60 md:-mt-24 z-0">
        <Container className="items-center max-w-4xl md:px-14">
          <Heading variant="h6" as="h6" className="text-center mt-56 md:mt-20">
            {content?.kicker}
          </Heading>
          <Heading variant="h2" as="h2" className="text-center mt-6">
            {content?.title}
          </Heading>
          <RichText text={content?.content} className="text-center mt-8" />
        </Container>
      </div>
    </div>
  );
};
