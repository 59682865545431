import { NumericFormat } from 'react-number-format';
import { classNames } from '~/lib';

export type NumberField = {
  name?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: any;
  fieldOptions?: any;
};

export const NumberInputField = ({
  field,
  onChange,
  value,
  className,
}: {
  field: NumberField;
  onChange: any;
  value: string;
  className?: string;
}) => {
  return (
    <div className={classNames('w-full', className)}>
      <label htmlFor={field?.name} className="block text-sm text-gray-900">
        {field?.label} {field?.required && <span> *</span>}
      </label>
      <div className="mt-1">
        <NumericFormat
          {...field?.fieldOptions}
          name={field?.name}
          id={field?.name}
          onChange={onChange}
          value={value}
          placeholder={field?.placeholder}
          defaultValue={field?.defaultValue}
          className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border-brand rounded-md"
        />
      </div>
    </div>
  );
};
