import { Image } from '@storyofams/storyblok-toolkit';

export const QuoteSection = ({ content }) => {
  return (
    <div className="py-20">
      <div className="w-full max-w-2xl px-4 mx-auto text-center flex flex-col items-center">
        {!!content?.image?.filename && (
          <div className="w-48 h-12 mb-10">
            <Image
              alt={content?.image?.alt || ''}
              src={content?.image?.filename}
              width="100%"
              className="max-h-12"
              fluid={250}
              fit="contain"
              showPlaceholder={false}
            />
          </div>
        )}
        <p className="text-3xl leading-[130%]">{content?.quote}</p>

        <div className="flex items-center mt-10 space-x-6">
          {!!content?.author_photo?.filename && (
            <div className="w-16 h-16 rounded-full overflow-hidden">
              <Image
                alt={content?.author_photo?.alt || ''}
                src={content?.author_photo?.filename}
                width="100%"
                height="100%"
                fluid={250}
                fit="cover"
                showPlaceholder={false}
                className="rounded-full"
              />
            </div>
          )}

          <div className="flex flex-col items-start text-left">
            <div className="font-medium text-lg">— {content?.author_name}</div>
            <div className="text-gray-600">{content?.author_position}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
