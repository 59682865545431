import { Image } from '@storyofams/storyblok-toolkit';
import { Heading } from '..';

interface InvestorsSectionProps {
  content?: any;
}

export const InvestorsSection = ({ content }: InvestorsSectionProps) => {
  return (
    <div className="flex flex-col items-center pb-24 max-w-7xl mx-auto px-5 md:px-10">
      <Heading variant="h6" as="h6" className="text-center text-gray-500">
        {content?.kicker}
      </Heading>
      <Heading variant="h3" as="h2" className="text-center mt-4">
        {content?.title}
      </Heading>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-20 gap-x-20 mt-16 mb-20 mx-auto">
        {content?.investors?.map((investor, i) => {
          return (
            <div
              key={investor?._uid}
              className="flex flex-col items-center w-[276px] justify-self-center"
            >
              <div className="h-20 w-20">
                <Image
                  alt={investor?.image?.alt || ''}
                  src={investor?.image?.filename}
                  fluid={80}
                  fit="contain"
                  showPlaceholder={false}
                  height="100%"
                  className="rounded-full"
                />
              </div>
              <div className="font-display text-xl text-center mt-6">
                {investor?.text}
              </div>
              <div className="text-gray-500 text-lg mt-2 text-center">
                {investor?.subtext}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
