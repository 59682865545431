import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { RichText } from '..';
import { Heading } from '..';

export const FeaturesLanding = ({ content }) => {
  return (
    <div className="w-full py-16 md:py-20">
      <div className="max-w-6xl px-4 mx-auto">
        <div className="max-w-3xl">
          {!!content?.title && (
            <Heading as="h3" variant="h3" className="!font-medium">
              {content?.title}
            </Heading>
          )}
          {!!content?.subtext && (
            <p className="max-w-[480px] mt-4 md:mt-5 md:text-lg text-gray-700">
              {content?.subtext}
            </p>
          )}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 mt-8 md:mt-16">
          {!!content?.cards &&
            content?.cards?.map((card) => (
              <div
                key={card?._uid}
                className="w-full bg-gradient-to-b from-gray-200 to-gray-100 rounded-lg border border-gray-300 overflow-hidden"
              >
                {!!card?.image?.filename && (
                  <Image
                    alt={card?.image?.alt || ''}
                    src={card?.image?.filename}
                    fluid={400}
                    fit="contain"
                    showPlaceholder
                    className={classNames('w-full rounded-t-lg relative z-0')}
                  />
                )}
                <div className="p-5 md:p-6 md:pt-8">
                  {!!card?.text && (
                    <Heading
                      as="h4"
                      variant="h5"
                      className="!text-lg lg:!text-xl"
                    >
                      {card?.text}
                    </Heading>
                  )}
                  {!!card?.subtext && (
                    <RichText
                      text={card?.subtext}
                      className="mt-2 text-gray-600"
                      textClassName="!text-sm md:!text-lg"
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
