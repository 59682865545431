import { ArrowLeftIcon } from '@heroicons/react/solid';
import { defineMessages, useIntl } from 'react-intl';
import { getLinkProps } from '~/lib';
import { Link, Container, Heading } from '..';
import { RichText } from '../Molecules';

interface HeaderSimpleProps {
  first?: boolean;
  content: {
    title?: any;
    description?: any;
    button_1_label?: string;
    button_1_url?: any;
    button_2_label?: string;
    button_2_url?: any;
  };
  subtext?: string;
  overviewLink?: any;
  overviewText?: string;
}

const messages = defineMessages({
  backButton: {
    id: 'header.backButton',
    defaultMessage: 'Back to overview',
  },
});

export const HeaderSimple = ({
  content,
  first,
  overviewLink,
  overviewText,
  subtext,
  ...props
}: HeaderSimpleProps) => {
  const { formatMessage } = useIntl();

  return (
    <Container
      first={first}
      className="lg:max-w-2xl text-lg mx-auto pb-10 lg:pb-12 lg:px-14"
      {...props}
    >
      <div className="text-center">
        {overviewLink && (
          <Link
            href={getLinkProps(overviewLink)}
            className="mb-7 sm:mb-9 mx-auto inline-flex items-center text-md font-medium text-gray-900 hover:text-gray-700"
          >
            <ArrowLeftIcon
              className="w-4 h-4 mr-2 -mt-0.5"
              aria-hidden="true"
            />
            {overviewText || formatMessage(messages.backButton)}
          </Link>
        )}
        {!!content?.title && (
          <Heading as="h1" variant="h2">
            {content?.title}
          </Heading>
        )}

        {subtext && (
          <p className="mx-auto mt-4 text-center text-base text-grey-700 sm:text-lg">
            {subtext}
          </p>
        )}

        {!!content?.description?.content?.[0]?.content && (
          <RichText
            className="max-w-xl mt-5 mx-auto items-center"
            textClassName="text-xl text-gray-700"
            text={content?.description}
          />
        )}

        {(!!content?.button_1_url || !!content?.button_2_url) && (
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            {!!content?.button_1_label && (
              <div>
                <Link
                  href={getLinkProps(content?.button_1_url)}
                  className="rounded-md shadow w-full flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium text-white bg-gray-900 hover:bg-gray-700 md:py-3 md:text-lg md:px-8"
                >
                  {content?.button_1_label}
                </Link>
              </div>
            )}

            {!!content?.button_2_label && (
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link
                  href={getLinkProps(content?.button_2_url)}
                  className="rounded-md shadow w-full flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium text-gray-600 bg-white hover:bg-gray-50 md:py-3 md:text-lg md:px-8"
                >
                  {content?.button_2_label}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};
