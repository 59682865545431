// import dynamic from 'next/dynamic';
import { Image } from '@storyofams/storyblok-toolkit';
import { Swiper, SwiperSlide } from 'swiper/react';

import { classNames } from '~/lib';
import { Heading } from '../Heading';

import 'swiper/css';
import { SwiperNav } from '../Molecules';

// I would probably suggest to add a skeleton / loading state for this component
// see https://nextjs.org/docs/advanced-features/dynamic-import#with-custom-loading-component
// const ScrollJack = dynamic(() => import('./ScrollJack'), { ssr: false });

interface ScrollSectionProps {
  section?: any;
  className?: string;
}

export const ScrollSection = ({ section, className }: ScrollSectionProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center mb-28 py-20 relative bg-gradient-to-b from-[#FFFEF9] to-yellow-50 overflow-hidden',
        className,
      )}
    >
      <div className="w-full">
        <div className="px-4 max-w-6xl mx-auto">
          <div className="max-w-[464px] flex flex-col items-start">
            <Heading variant="h5" as="h5" className="text-xl text-yellow-600">
              {section?.kicker}
            </Heading>
            <Heading variant="h3" as="h3" className="mt-4">
              {section?.title}
            </Heading>
          </div>
        </div>
        {/* <ScrollJack section={section} className="hidden lg:flex" /> */}
        <Swiper
          className="mt-12 p-swiper"
          slidesPerView={1.2}
          spaceBetween={24}
          breakpoints={{
            1024: {
              spaceBetween: 24,
              slidesPerView: 2,
            },
          }}
        >
          {!!section?.slides &&
            section?.slides?.map((slide) => (
              <SwiperSlide
                key={slide?._uid}
                className="min-w-[304px] lg:max-w-[528px]"
              >
                <div className="w-full rounded-xl overflow-hidden bg-yellow-200 border border-brand">
                  {!!slide?.image && (
                    <Image
                      alt={slide?.image?.alt || ''}
                      src={slide?.image?.filename}
                      fit="contain"
                      showPlaceholder={false}
                      className={classNames('w-full rounded-xl')}
                    />
                  )}
                </div>
                <div className="mt-6 text-grey-700 max-w-xs text-lg rounded">
                  {slide?.text}
                </div>
              </SwiperSlide>
            ))}
          <SwiperSlide className="w-12" />
          <SwiperNav buttonClassName="bg-white !border-grey-200" />
        </Swiper>
      </div>
    </div>
  );
};
