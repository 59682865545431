import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { Container, Heading } from '..';

interface HeaderImageProps {
  content: {
    kicker?: string;
    title?: string;
    background?: any;
    image?: any;
    alignment?: 'center' | 'left';
  };
}

export const HeaderImage = ({ content, ...props }: HeaderImageProps) => {
  return (
    <div>
      <div
        className={classNames(
          'pt-28 md:pt-36 pb-44 md:pb-72',
          content?.background ? `bg-${content?.background}` : 'bg-yellow-50',
          !!content?.image?.filename ? '-mb-72 md:-mb-80' : '-mb-40 md:-mb-48',
        )}
      >
        <div className="max-w-6xl px-4 mx-auto">
          {!!content?.kicker && (
            <Heading as="h6" variant="h6" className="mb-1">
              {content.kicker}
            </Heading>
          )}
          {!!content?.title && (
            <Heading
              as="h1"
              variant="h1"
              className={classNames(
                '!font-medium',
                content?.alignment === 'left'
                  ? 'text-left'
                  : 'text-center max-w-xl mx-auto',
              )}
            >
              {content.title}
            </Heading>
          )}
        </div>
      </div>
      {!!content?.image?.filename && (
        <Container first className="mx-0 md:mx-auto md:px-5">
          <div className="w-full h-52 md:h-[360px] rounded-2xl overflow-hidden relative top-10 md:top-0 image--rounded">
            <Image
              alt={content?.image?.alt || ''}
              src={content?.image?.filename}
              fit="cover"
              showPlaceholder={false}
              height="100%"
              className="h-52 md:h-[360px] rounded-2xl image--rounded relative"
            />
          </div>
        </Container>
      )}
    </div>
  );
};
