import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { classNames } from '~/lib';
import { StepsCard } from '../Home';
import { RichText, SwiperNav } from '../Molecules';

import 'swiper/css';

export const CardSwiper = ({
  content,
  className,
}: {
  content?: any;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center pb-28 relative overflow-hidden',
        className,
      )}
    >
      <div className="w-full">
        <div className="max-w-6xl px-4 mx-auto">
          <div className="max-w-2xl text-center md:text-left flex flex-col items-start mb-10 md:mb-16">
            {!!content?.title && (
              <RichText
                text={content?.title}
                className="text-center md:text-left"
              />
            )}
            {!!content?.subtitle && (
              <p className="mt-6 text-lg">{content?.subtitle}</p>
            )}
          </div>
        </div>

        {!!content?.cards && (
          <div className="px-4 flex flex-col space-y-2 sm:hidden">
            {content?.cards?.map((card) => (
              <StepsCard key={card?._uid} card={card} />
            ))}
          </div>
        )}
        {!!content?.cards && (
          <Swiper
            className="!hidden sm:!block p-swiper"
            spaceBetween={56}
            modules={[Navigation]}
            navigation
            slidesPerView={1.5}
          >
            {content?.cards?.map((card) => (
              <SwiperSlide key={card?._uid} className="max-w-[807px]">
                <StepsCard card={card} />
              </SwiperSlide>
            ))}
            <SwiperNav />
          </Swiper>
        )}
      </div>
    </div>
  );
};
