export const Phone = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M.4 1.6A1.2 1.2 0 0 1 1.6.4h2.584a1.2 1.2 0 0 1 1.183 1.003l.888 5.322a1.2 1.2 0 0 1-.648 1.272l-1.857.928a13.245 13.245 0 0 0 7.326 7.326l.928-1.858a1.2 1.2 0 0 1 1.271-.648l5.322.888a1.2 1.2 0 0 1 1.003 1.183V18.4a1.2 1.2 0 0 1-1.2 1.2H16C7.384 19.6.4 12.616.4 4V1.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
