import { Richtext } from '@storyofams/storyblok-toolkit';

import { ImageFragmentFragment } from '~/graphql/sdk';
import { Field, Form, FormContainer } from '../Forms';

export type DynamicFormProps = {
  className?: string;
  content: {
    form_id: string;
    formFieldGroups: { fields: Field[] }[];
    kicker?: string;
    title?: string;
    subtext?: string;
    button_text?: string;
    privacy_policy?: Richtext;
    layout?: 'simple' | 'features';
    image?: ImageFragmentFragment;
    image_subtext?: string;
    features?: any[];
    top_padding?: boolean;
    form_type?: string;
    event?: string;
    id?: string;
  };
};

export const DynamicForm = ({ content, className }: DynamicFormProps) => {
  return (
    <FormContainer content={content} className={className}>
      <Form content={content} />
    </FormContainer>
  );
};
