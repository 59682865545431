import { Image } from '@storyofams/storyblok-toolkit';
import SbEditable from 'storyblok-react';

import { RichText } from '~/components';
import { classNames } from '~/lib';
import { Form } from '../Forms';
import { Heading } from '../Heading';
import { smallFeatureIcons } from './FeatureSection';

export interface FeaturesFormProps {
  className?: string;
  content?: any;
}

export const FeaturesForm = ({ className, content }: FeaturesFormProps) => {
  return (
    <SbEditable content={content}>
      <div
        className={classNames('pb-20 lg:pb-24', 'pt-20 md:pt-32', className)}
      >
        <div
          className={classNames(
            'flex flex-col items-start lg:flex-row relative max-w-6xl px-4 mx-auto lg:space-x-16',
          )}
        >
          <div
            className={classNames(
              'relative w-full mx-auto max-w-lg lg:max-w-xl',
            )}
          >
            {!!content?.kicker && (
              <Heading as="h6" variant="h6" className="text-gray-600">
                {content?.kicker}
              </Heading>
            )}
            {!!content?.title && (
              <Heading as="h3" variant="h3" className="text-grey-800 mt-2">
                {content?.title}
              </Heading>
            )}
            {!!content?.subtext && (
              <p className="text-gray-600 mt-4 lg:hidden">{content?.subtext}</p>
            )}

            <div className="w-full max-w-lg mx-auto bg-yellow-50 border border-yellow-200 rounded-2xl px-5 py-6 lg:px-8 lg:hidden mt-6 relative">
              {!!content?.form_title && (
                <Heading as="h5" variant="h5">
                  {content?.form_title}
                </Heading>
              )}

              <Form content={content} className="mt-6 lg:mt-8 !space-y-4" />
            </div>

            {!!content?.features && (
              <div className="w-full max-w-sm mx-auto lg:mx-0 flex flex-col space-y-8 mt-8 lg:mt-10">
                {content?.features?.map((feat) => {
                  const Icon = smallFeatureIcons.find(
                    (i) => i.value === feat?.icon,
                  )?.icon;

                  return (
                    <div key={feat?._uid} className="flex items-start">
                      <div className="flex-shrink-0 w-14 h-14 bg-yellow-100 border-[6px] border-yellow-50 rounded-full flex items-center justify-center mr-5">
                        {!!Icon && <Icon />}
                      </div>
                      {!!feat?.richtext && (
                        <RichText
                          text={feat?.richtext}
                          textClassName="text-sm text-grey-800"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {!!content?.quote?.[0] && (
              <div className="max-w-md mx-auto lg:mx-0 border-l-2 border-yellow-300 pl-8 mt-14">
                <p className="text-lg italic">{content?.quote?.[0]?.quote}</p>
                <div className="flex mt-6 items-center space-x-4">
                  {!!content?.quote?.[0]?.image && (
                    <div className="w-16 h-16 flex-shrink-0 rounded-full overflow-hidden">
                      <Image
                        alt={content?.quote?.[0]?.image?.alt || ''}
                        src={content?.quote?.[0]?.image?.filename}
                        fluid={64}
                        fit="contain"
                        showPlaceholder={false}
                        className={classNames('w-full max-w-100 rounded-full')}
                      />
                    </div>
                  )}
                  <div className="text-sm">
                    <div className="font-medium">
                      {content?.quote?.[0]?.author}
                    </div>
                    <div>{content?.quote?.[0]?.subtext}</div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="w-full max-w-lg bg-yellow-50 border border-yellow-200 rounded-2xl px-5 py-6 lg:px-8 hidden lg:block relative">
            {!!content?.form_title && (
              <Heading as="h5" variant="h5">
                {content?.form_title}
              </Heading>
            )}

            <Form content={content} className="mt-6 lg:mt-8 !space-y-4" />
          </div>
        </div>
      </div>
    </SbEditable>
  );
};
