import { Image } from '@storyofams/storyblok-toolkit';
import SbEditable from 'storyblok-react';
import { classNames, getLinkProps } from '~/lib';
import { MoleculeDynamic, Video } from '../Molecules';

export const ContentAsset = ({ content }) => {
  return (
    <div className="w-full py-16 md:py-20 overflow-hidden">
      <div
        className={classNames(
          'w-full max-w-6xl px-4 mx-auto flex flex-col lg:space-x-16 lg:items-center lg:justify-between relative',
          content?.layout === 'content-right'
            ? 'lg:flex-row-reverse space-y-12 lg:space-y-0'
            : content?.layout === 'content-center'
            ? 'lg:flex-col space-y-8 lg:space-y-12'
            : 'lg:flex-row space-y-12 lg:space-y-0',
        )}
      >
        <div
          className={classNames(
            'w-full max-w-prose mx-auto lg:mx-0 space-y-6',
            content?.layout === 'content-center'
              ? 'lg:max-w-3xl'
              : 'lg:max-w-[480px]',
          )}
        >
          {content?.content?.map((section) => {
            return (
              <SbEditable content={section} key={section?._uid}>
                <div
                  className={classNames(
                    content?.layout === 'content-center' &&
                      section?.component !== 'checklist' &&
                      'text-center flex flex-col justify-center',
                  )}
                >
                  <MoleculeDynamic
                    content={section}
                    sectionType={section?.component}
                    component={content?.component}
                  />
                </div>
              </SbEditable>
            );
          })}
        </div>
        <div
          className={classNames(
            'hidden',
            content?.layout !== 'content-center' && 'lg:block lg:h-[460px]',
          )}
        />
        <div
          className={classNames(
            'w-full lg:max-w-[820px] rounded-2xl overflow-hidden',
            content?.layout === 'content-center'
              ? 'lg:max-w-4xl'
              : content?.layout === 'content-right'
              ? 'lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:-left-[36%] lg:max-w-[820px]'
              : 'lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:-right-[27%] lg:max-w-[820px]',
          )}
        >
          {content?.asset === 'video' ? (
            <Video url={getLinkProps(content?.video)} />
          ) : (
            <Image
              alt={content?.image?.alt}
              src={content?.image?.filename}
              fit="cover"
              showPlaceholder={false}
              width="100%"
              className="rounded-2xl"
            />
          )}
        </div>
      </div>
    </div>
  );
};
