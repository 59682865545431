export const TrendingBgMobile = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 757 251"
    >
      <path
        opacity=".1"
        d="M128.1 164.669 1 209.768V251h755V1l-95.39 93.863-110.591-7.255L443.622 190.5 342.708 240l-115.287-55.575-99.321-19.756Z"
        fill="url(#a)"
      />
      <path
        d="m1 210 127.1-45.149 99.321 19.777L340.208 239.5 446.5 192 550.019 87.704l110.591 7.263L756 1"
        stroke="#F3F4F6"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="a"
          x1="344.515"
          y1="34.142"
          x2="367.961"
          y2="208.735"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
