import { Heading } from '../Heading';
import { Check } from '../Icons';
import { DoubleWave } from '../Vectors';

export const Features = ({ content }) => {
  return (
    <div className="w-full max-w-6xl px-4 mx-auto py-20">
      <div className="max-w-[690px] mx-auto mb-16">
        <Heading as="h2" variant="h2" className="!font-medium text-center">
          {content?.title}
        </Heading>
        {!!content?.subtitle && (
          <div className="text-lg text-gray-700 text-center mt-5">
            {content?.subtitle}
          </div>
        )}
      </div>
      {!!content?.features && (
        <div className="w-full py-8 px-4 md:p-10 grid grid-cols-1 justify-start md:grid-cols-2 gap-x-10 bg-gradient-to-b from-[#FFFEF9] to-yellow-50 rounded-2xl border relative overflow-hidden">
          {content?.features?.map((feat, i) => (
            <div
              key={i}
              className="text-gray-800 flex md:odd:border-r py-4 md:py-5 md:pr-5 relative z-10"
            >
              <div className="h-6 w-6 rounded-full bg-black mr-3 flex justify-center items-center shrink-0">
                <Check className="text-white w-3" />
              </div>
              <div>{feat?.text}</div>
            </div>
          ))}
          <DoubleWave className="absolute bottom-0 right-0 left-0 h-auto opacity-80 blur-lg w-screen z-0 text-white  scale-x-[-1]" />
        </div>
      )}
    </div>
  );
};
