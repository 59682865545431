import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { MoleculeDynamic } from '../Molecules';

export const ContentBg = ({ content }) => {
  return (
    <div className="w-full my-20 py-24 relative">
      {!!content?.bg?.filename && (
        <div
          className={classNames(
            'absolute inset-0 z-0',
            !!content?.bg_mobile?.filename && 'hidden md:block',
          )}
        >
          <Image
            alt={content?.bg?.alt}
            src={content?.bg?.filename}
            fit="cover"
            showPlaceholder={false}
            width="100%"
            height="100%"
          />
        </div>
      )}

      {!!content?.bg_mobile?.filename && (
        <div className="absolute inset-0 z-0 md:hidden">
          <Image
            alt={content?.bg_mobile?.alt}
            src={content?.bg_mobile?.filename}
            fit="cover"
            showPlaceholder={false}
            width="100%"
            height="100%"
          />
        </div>
      )}

      <div className="px-4 max-w-prose mx-auto relative z-10">
        {content?.content?.map((block) => (
          <MoleculeDynamic
            key={block?._uid}
            sectionType={block?.component}
            content={block}
          />
        ))}
      </div>
    </div>
  );
};
