export const TrendingBg = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1443 480"
    >
      <path
        opacity=".2"
        d="M241.089 314.59-2 401v79h1444V1l-182.44 179.841-211.51-13.9L844.552 285.64l-193.007-68.535L431.05 352.442 241.089 314.59Z"
        fill="url(#a)"
      />
      <path
        d="m-2 401 243.089-86.41 189.961 37.852 220.495-135.337 193.007 68.535 203.498-118.699 211.51 13.9L1442 1"
        stroke="#E5E7EB"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="a"
          x1="655"
          y1="64.5"
          x2="700"
          y2="399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
