import { CheckIcon } from '@heroicons/react/solid';
import { NumericFormat } from 'react-number-format';

import { LinkFragmentFragment } from '~/graphql/sdk';
import { classNames, getLinkProps } from '~/lib';
import { Button } from '.';

interface PricingCardProps {
  kicker?: string;
  price?: string;
  subtext?: string;
  perks?: any[];
  button_text?: string;
  button_link?: LinkFragmentFragment;
  plan?: 'monthly' | 'annually';
  most_popular?: boolean;
  discount?: string;
}

export const PricingCard = ({
  kicker,
  price,
  discount,
  subtext,
  perks,
  button_text,
  button_link,
  plan,
  most_popular,
}: PricingCardProps) => {
  const yearPrice =
    parseInt(price?.split('€')?.[1]) * (1 - 1 / parseInt(discount));

  return (
    <div
      className={classNames(
        'w-full rounded-3xl overflow-hidden relative px-6 py-8 md:px-12 md:py-12 bg-white border',
        'flex flex-col justify-between',
        most_popular && 'border-gray-300 shadow-md',
      )}
    >
      <div>
        <div
          className={classNames(
            'pb-6 border-b flex flex-col items-start relative',
          )}
        >
          {most_popular && (
            <div className="bg-navy-100 text-xs py-1 px-2 sm:py-2 sm:px-4 rounded-full self-end absolute -top-4 -right-2">
              Most popular
            </div>
          )}

          {!!kicker && <div className="text-grey-700 sm:mb-1">{kicker}</div>}
          <div className="flex items-center">
            <div className="flex items-baseline text-4xl sm:text-6xl font-medium">
              {!!yearPrice ? (
                plan === 'monthly' ? (
                  <NumericFormat displayType="text" value={price} prefix="€" />
                ) : (
                  <NumericFormat
                    displayType="text"
                    value={yearPrice}
                    prefix="€"
                  />
                )
              ) : (
                price
              )}

              {!!yearPrice && (
                <span className="text-xl sm:text-2xl">/month</span>
              )}
            </div>
          </div>

          {!!subtext && <div className="mt-2 sm:mt-4">{subtext}</div>}
        </div>

        <div className="flex flex-col space-y-4 sm:space-y-5 pt-6">
          {perks?.map((perk) => (
            <div key={perk?._uid} className="flex items-start">
              <div className="w-6 h-6 bg-yellow-50 rounded-full flex shrink-0 items-center justify-center">
                <CheckIcon className="w-3 text-yellow-600" />
              </div>
              <p className="ml-4">{perk?.text}</p>
            </div>
          ))}
        </div>
      </div>

      <Button
        variant="primary"
        className="w-full mt-8"
        href={getLinkProps(button_link)}
      >
        {button_text || 'Start for free'}
      </Button>
    </div>
  );
};
