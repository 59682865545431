import { useState } from 'react';
import { Root as Switch, Thumb } from '@radix-ui/react-switch';
import { classNames } from '~/lib';
import { Heading, PricingCard } from '..';

export const PricingSection = ({ content }: { content: any }) => {
  const [monthly, setMonthly] = useState<boolean>(true);

  return (
    <div className="w-full">
      <div className="w-full bg-yellow-50 py-20 pt-32 lg:pt-40 pb-40 md:pb-48 relative overflow-hidden">
        <div className="w-full max-w-6xl px-4 mx-auto text-center flex flex-col justify-center items-center relative z-20">
          {!!content?.title && (
            <Heading as="h1" variant="h1" className="!font-medium">
              {content?.title}
            </Heading>
          )}
          {!!content?.subtitle && (
            <p className="text-xl mt-6 text-gray-800">{content?.subtitle}</p>
          )}

          <Switch
            checked={monthly}
            className="bg-white border rounded-full p-1.5 flex items-center w-full max-w-[270px] mt-10 text-xs text-gray-700 font-medium relative"
          >
            <Thumb className="absolute data-[state-checked]:left-1.5 w-[95px] h-[38px] bg-grey-100 rounded-full z-0 data-[state=unchecked]:w-[160px] data-[state=unchecked]:translate-x-24 transition-all" />

            <button
              onClick={() => setMonthly(!monthly)}
              className={classNames('rounded-full px-6 py-2.5 relative z-10')}
            >
              Monthly
            </button>
            <button
              onClick={() => setMonthly(!monthly)}
              className={classNames(
                'rounded-full flex-1 shrink-0 relative z-10',
              )}
            >
              Annually
              <span className="text-gray-400">
                {' '}
                {content?.annual_savings_text}
              </span>
            </button>
          </Switch>
        </div>

        <div className="w-60 h-60 rounded-full bg-gradient-to-br from-yellow-300/70 absolute top-24 left-28 blur-2xl z-0" />
        <div className="w-80 h-80 rounded-full bg-gradient-to-l from-yellow-300/70 absolute -bottom-32 right-52 blur-2xl z-0" />
      </div>

      <div className="max-w-6xl mx-auto px-4 mb-20 -mt-32">
        <div className="grid grid-cols-1 gap-y-4 lg:gap-y-0 lg:grid-cols-3 lg:gap-x-6">
          {content?.pricing_cards?.map((card) => (
            <PricingCard
              key={card?._uid}
              {...card}
              plan={monthly ? 'monthly' : 'annually'}
              discount={content?.annual_discount}
            />
          ))}
        </div>

        {!!content?.subtext && (
          <div className="text-center mx-auto text-gray-400 mt-6 lg:mt-8">
            {content?.subtext}
          </div>
        )}
      </div>
    </div>
  );
};
