export const LocationPin = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 21"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06 2.86a8.4 8.4 0 1 1 11.88 11.88L9 20.68l-5.94-5.94a8.4 8.4 0 0 1 0-11.88ZM9 11.2a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z"
        fill="currentColor"
      />
    </svg>
  );
};
