import { CheckIcon } from '@heroicons/react/solid';
import {
  Root as TooltipRoot,
  Trigger as TooltipTrigger,
  Content as TooltipContent,
  TooltipArrow,
} from '@radix-ui/react-tooltip';
import { classNames } from '~/lib';

import { Button } from '../Button';
import { Heading } from '../Heading';

export const PlanComparisonSection = ({ content }) => {
  const getCellContent = ({ value }: { value: string }) => {
    let cellContent = null;

    const trimmedValue = value?.toLowerCase().trim();

    switch (trimmedValue) {
      case 'yes':
        cellContent = (
          <div>
            <span className="sr-only">Yes</span>
            <CheckIcon className="w-4 text-yellow-700" aria-hidden />
          </div>
        );
        break;
      case 'no':
        cellContent = (
          <div>
            <span className="sr-only">No</span>
            <span aria-hidden>—</span>
          </div>
        );
        break;
      default:
        cellContent = (
          <div className="flex items-center">
            <CheckIcon className="w-4 text-yellow-700 mr-2" aria-hidden />
            <span>{value}</span>
          </div>
        );
        break;
    }

    return cellContent;
  };

  return (
    <div className="hidden md:block w-full max-w-6xl px-4 mx-auto py-20">
      {!!content?.title && (
        <Heading as="h2" variant="h2" className="!font-medium">
          {content?.title}
        </Heading>
      )}
      {!!content?.subtitle && (
        <p className="text-lg mt-5 text-gray-700">{content?.subtitle}</p>
      )}

      <table className="w-full mt-20 table-fixed border-separate border-spacing-0">
        <thead>
          <tr>
            {content?.comparison_table?.thead?.map((cell, i) => {
              const cellValue = cell?.value?.split('\n');

              return (
                <th
                  key={i}
                  className={classNames(
                    'w-full text-2xl px-5 py-10 border-b border-gray-300',
                    i === 3 && 'bg-grey-50 border rounded-t-lg relative',
                    i === 1 && 'hidden',
                  )}
                >
                  {i === 3 && (
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-navy-100 rounded-full text-xs py-2 px-4 font-normal">
                      Most popular
                    </div>
                  )}

                  <div className="font-medium">{cellValue?.[0]}</div>

                  {!!cellValue?.[1] && (
                    <div className="text-base text-gray-700 font-medium mt-1">
                      {cellValue?.[1]}
                    </div>
                  )}

                  {(i === 2 || i === 3) && (
                    <Button
                      variant="dark"
                      className={classNames('w-full mt-5')}
                      href="/onboarding"
                    >
                      {content?.onboarding_cta}
                    </Button>
                  )}

                  {i === 4 && (
                    <Button
                      variant="dark"
                      className="w-full mt-5"
                      href={'/contact'}
                    >
                      {content?.contact_cta}
                    </Button>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {content?.comparison_table?.tbody?.map((row, i) => {
            const isHeadingRow = row?.body
              ?.slice(1)
              ?.every((cell) => !cell?.value);

            return (
              <tr key={i}>
                {row?.body?.map((cell, ci) => {
                  const hasTooltip = ci === 0 && !!row?.body?.[ci + 1]?.value;

                  return (
                    <TooltipRoot delayDuration={0} key={ci}>
                      <td
                        key={ci}
                        className={classNames(
                          'border-gray-300 px-5 first:pl-0 last:pr-0',
                          isHeadingRow
                            ? 'pb-3 pt-6 font-medium'
                            : 'text-sm pt-4',
                          ci === 3 && 'bg-grey-50 border-l border-r',
                          ci === 1 && 'hidden',
                        )}
                      >
                        {hasTooltip ? (
                          <div>
                            <TooltipTrigger className="underline decoration-dashed decoration-gray-300 underline-offset-4">
                              {cell?.value}
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className="py-2 px-3 bg-gray-900 rounded-lg text-white text-xs shadow-md">
                                {row?.body?.[ci + 1]?.value}
                              </div>
                              <TooltipArrow />
                            </TooltipContent>
                          </div>
                        ) : ci === 0 || isHeadingRow ? (
                          <span>{cell?.value}</span>
                        ) : (
                          getCellContent({ value: String(cell?.value) })
                        )}
                        <div
                          aria-hidden
                          className={classNames(
                            !isHeadingRow && 'w-full border-b pt-4',
                          )}
                        />
                      </td>
                    </TooltipRoot>
                  );
                })}
              </tr>
            );
          })}

          <tr aria-hidden>
            <td />
            <td />
            <td className="h-10 bg-grey-50 border border-t-0 border-gray-300 rounded-b-lg shadow-md" />
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};
