import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { classNames } from '~/lib';
import { Heading } from '../Heading';
import { CaseCard } from '../Landing';
import { SwiperNav } from '../Molecules';

import 'swiper/css';

export const CasesSwiper = ({ content }) => {
  const container = useRef();

  return (
    <div ref={container} className="w-full py-16 md:py-20">
      <div className="w-full max-w-6xl px-4 mx-auto text-center mb-14">
        {!!content?.title && (
          <Heading as="h3" variant="h3" className="!font-medium">
            {content?.title}
          </Heading>
        )}
      </div>
      <Swiper
        className="w-full !px-4"
        slidesPerView="auto"
        centeredSlides={true}
        loop={true}
        loopAdditionalSlides={content?.cases?.length}
        loopFillGroupWithBlank={true}
        spaceBetween={40}
      >
        {content?.cases?.map((card) => {
          const isLink = !!card?.link;

          return (
            <SwiperSlide
              id="slide"
              key={card?._uid}
              className={classNames(
                'max-w-[744px] bg-gray-200 rounded-2xl px-4 py-8 md:p-12 overflow-hidden group select-none !h-auto',
                isLink && 'cursor-pointer',
              )}
            >
              <CaseCard card={card} />
            </SwiperSlide>
          );
        })}
        <SwiperNav className="w-full justify-center !mt-12" />
      </Swiper>
    </div>
  );
};
