import { Image } from '@storyofams/storyblok-toolkit';
import { getLinkProps } from '~/lib';
import { Heading } from '../Heading';
import { Link } from '../Link';

export const CaseCard = ({ card }) => {
  const isLink = !!card?.link;

  return (
    <div
      className={
        isLink ? '' : 'flex flex-col items-center justify-center text-center'
      }
    >
      {isLink ? (
        <Link
          href={getLinkProps(card?.link)}
          className="flex flex-col items-center justify-center text-center"
        >
          {!!card?.logo && (
            <div className="max-w-[120px] w-full mb-8 md:mb-10">
              <Image
                alt={card?.logo?.alt || ''}
                src={card?.logo?.filename}
                fluid={120}
                fit="contain"
                showPlaceholder
                width="100%"
              />
            </div>
          )}
          {!!card?.title && (
            <Heading as="h4" variant="h4" className="max-w-[456px]">
              {card?.title}
            </Heading>
          )}
          <div className="w-9 border border-gray-500 my-8" />
          <div className="w-full flex flex-row md:justify-between">
            {!!card?.metrics &&
              card?.metrics?.map((metric) => (
                <div
                  key={metric?._uid}
                  className="flex-1 last:hidden md:last:block"
                >
                  <div className="text-4xl md:text-5xl font-medium text-grey-800">
                    {metric?.before_number}
                    <span>{metric?.number}</span>
                    {metric?.after_number}
                  </div>
                  <div className="mt-1 text-sm text-grey-700">
                    {metric?.subtext}
                  </div>
                </div>
              ))}
          </div>
          <div className="w-full rounded-lg overflow-hidden mt-10 -mb-16 md:-mb-[306px] shadow-md group-hover:-translate-y-32 transition-transform duration-300">
            {!!card?.image && (
              <Image
                alt={card?.image?.alt || ''}
                src={card?.image?.filename}
                fluid={650}
                fit="contain"
                showPlaceholder
                width="100%"
              />
            )}
          </div>
        </Link>
      ) : (
        <>
          {!!card?.logo && (
            <div className="max-w-[120px] w-full mb-8 md:mb-10">
              <Image
                alt={card?.logo?.alt || ''}
                src={card?.logo?.filename}
                fluid={120}
                fit="contain"
                showPlaceholder
                width="100%"
              />
            </div>
          )}
          {!!card?.title && (
            <Heading as="h4" variant="h4" className="max-w-[456px]">
              {card?.title}
            </Heading>
          )}
          <div className="w-9 border border-gray-500 my-8" />
          <div className="w-full flex flex-row md:justify-between">
            {!!card?.metrics &&
              card?.metrics?.map((metric) => (
                <div
                  key={metric?._uid}
                  className="flex-1 last:hidden md:last:block"
                >
                  <div className="text-4xl md:text-5xl font-medium text-grey-800">
                    {metric?.before_number}
                    <span>{metric?.number}</span>
                    {metric?.after_number}
                  </div>
                  <div className="mt-1 text-sm text-grey-700">
                    {metric?.subtext}
                  </div>
                </div>
              ))}
          </div>
          <div className="w-full rounded-lg overflow-hidden mt-10 -mb-16 md:-mb-[306px] shadow-md group-hover:-translate-y-32 transition-transform duration-300">
            {!!card?.image && (
              <Image
                alt={card?.image?.alt || ''}
                src={card?.image?.filename}
                fluid={650}
                fit="contain"
                showPlaceholder
                width="100%"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
