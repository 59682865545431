import { useState } from 'react';
import { getCookies } from 'cookies-next';
import { useForm } from 'react-hook-form';

import { Button, Container } from '~/components';
import { classNames, track } from '~/lib';

export interface ContactFormProps {
  className?: string;
}

export const ContactForm = ({ className }: ContactFormProps) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [agreed, setAgreed] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values) => {
    setSuccess('');

    if (!agreed) {
      setError('Please agree to the terms to continue');
      return;
    }

    setError('');

    try {
      const res = await fetch('/api/postmark', {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' },
      });

      if (res.status === 200) {
        setSuccess('Your message has been sent!');
        reset();
        track({
          formType: 'CONTACT',
          event: 'generate_lead',
        });
      } else {
        const data = await res.json();
        setError(data.message);
      }
    } catch (e) {
      setError('An unknown error has occurred');
    }

    return false;
  };

  return (
    <Container className={classNames('pt-0 lg:pt-0', className)}>
      <div className="relative max-w-xl mx-auto">
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm text-gray-900">
                Full name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  {...register('name', { required: true })}
                  autoComplete="name"
                  placeholder="Full name"
                  defaultValue=""
                  className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border-brand rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-700"
              >
                Company
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="company"
                  id="company"
                  {...register('company', { required: true })}
                  autoComplete="organization"
                  placeholder="Company"
                  defaultValue=""
                  className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border-brand rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register('email', { required: true })}
                  autoComplete="email"
                  placeholder="Work email"
                  defaultValue=""
                  className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border-brand rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  {...register('message', { required: true })}
                  rows={4}
                  placeholder="Type your message..."
                  className="py-3 px-4 block w-full focus:ring-2 focus:ring-yellow-300 focus:border-brand shadow-yellow-sm border border-brand rounded-md"
                  defaultValue=""
                />
              </div>
            </div>
            <div>
              <div className="flex items-start">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={agreed}
                    onChange={() => setAgreed(!agreed)}
                    className={classNames(
                      agreed ? 'text-gray-900' : 'bg-white',
                      'rounded border-transparent focus:ring-2 focus:ring-yellow-300 focus:ring-offset-0 focus:border-brand shadow-yellow-sm border border-brand',
                    )}
                  />
                  <p className="text-base text-gray-900 ml-3">
                    By selecting this, you agree to the{' '}
                    <a
                      href="/privacy-policy"
                      className="text-gray-900 font-medium underline"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </label>
              </div>
            </div>
            <Button
              variant="primary"
              aria-label="Submit"
              size="large"
              type="submit"
              isLoading={isSubmitting}
              className="col-span-2"
            >
              Submit
            </Button>

            {(success || error) && (
              <div
                className={classNames(
                  'mt-4 text-lg sm:col-span-2',
                  error ? 'text-red-500' : 'text-green-500',
                )}
              >
                {success || error}
              </div>
            )}
            <input
              className="absolute"
              style={{ left: '-9999px' }}
              aria-hidden="true"
              type="text"
              name="b_35cf2612bb33321a60e61d239_7eb72358b5"
              tabIndex={-1}
            />
          </form>
        </div>
      </div>
    </Container>
  );
};
