import { useEffect, useState } from 'react';
import { getLinkProps } from '~/lib';
import { Heading, Link, TeamCard } from '..';
import { ArrowRight } from '../Icons';

interface TeamSectionProps {
  content?: any;
}

export const TeamSection = ({ content }: TeamSectionProps) => {
  const [members, setMembers] = useState<any[]>([
    ...(content?.founders ?? []),
    ...(content?.team ?? []),
  ]);

  useEffect(() => {
    const isFirefox = typeof window['InstallTrigger'] !== 'undefined';

    if (!isFirefox) {
      setMembers([
        ...(content?.founders ?? []),
        ...(content?.team.sort(() => 0.5 - Math.random()) ?? []),
      ]);
    }
  }, []);

  return (
    <div className="flex flex-col items-center max-w-6xl px-4 md:px-5 mx-auto pb-24">
      <Heading variant="h2" as="h2" className="text-center">
        {content?.title}
      </Heading>
      <div className="flex flex-col items-center w-full md:grid md:grid-cols-2 lg:grid lg:grid-cols-3 md:gap-8 lg:gap-12 mt-16">
        {members?.map((person) => (
          <TeamCard key={person?.uuid} person={person} />
        ))}
      </div>
      <div className="text-2xl mt-16">{content?.subtext}</div>
      <Link
        href={getLinkProps(content?.link)}
        className="mt-2 text-lg font-bold flex group"
      >
        <div>{content?.link_text}</div>
        <ArrowRight className="w-3 h-7 ml-2 group-hover:transform group-hover:translate-x-2 transition-transform" />
      </Link>
    </div>
  );
};
