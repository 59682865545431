import { Image } from '@storyofams/storyblok-toolkit';
import { classNames, getLinkProps } from '~/lib';
import { Link, Container, Heading } from '..';
import { ArrowRight } from '../Icons';
import { RichText } from '../Molecules';
import { DoubleWave } from '../Vectors';
import { Wave } from '../Wave';

interface HeaderProps {
  first?: boolean;
  content: {
    title?: any;
    description?: any;
    button_1_label?: string;
    button_1_url?: any;
    button_2_label?: string;
    button_2_url?: any;
    image_main?: any;
    image_secondary?: any;
  };
}

export const Header = ({ content, first, ...props }: HeaderProps) => {
  return (
    <div className="w-full bg-grey-100 lg:bg-grey-100 relative">
      <div className="h-full w-3/12 bg-yellow-100 absolute top-0 right-0 z-0 hidden lg:block" />
      <div className="w-full absolute text-white bottom-0 left-0 z-10 hidden lg:block">
        <DoubleWave opacity="0.7" className="w-full" />
        <div className="bg-white/70 h-40" />
      </div>

      <Container
        first={first}
        className="pb-0 lg:pb-0 relative lg:flex-row w-full z-10"
        {...props}
      >
        <div className="max-w-[548px] mx-auto lg:mx-0 z-30 lg:mt-12">
          {!!content?.title && (
            <Heading as="h1" variant="h2">
              {content?.title}
            </Heading>
          )}

          {!!content?.description?.content?.[0]?.content && (
            <RichText
              className="mt-4 lg:mt-6"
              textClassName="lg:text-lg text-gray-700"
              text={content?.description}
            />
          )}

          {(!!content?.button_1_url || !!content?.button_2_url) && (
            <div className="mt-12 flex items-center">
              {!!content?.button_1_label && (
                <div>
                  <Link
                    href={getLinkProps(content?.button_1_url)}
                    className="rounded-md shadow w-full flex items-center justify-center px-8 py-4 border border-transparent text-gray-800 bg-yellow-500 font-medium"
                  >
                    {content?.button_1_label}
                  </Link>
                </div>
              )}

              {!!content?.button_2_label && (
                <div className="ml-4">
                  <Link
                    href={getLinkProps(content?.button_2_url)}
                    className="flex items-center justify-center px-4 py-2 font-medium text-gray-800 group"
                  >
                    {content?.button_2_label}
                    <ArrowRight className="w-3 ml-3 group-hover:transform group-hover:translate-x-2 transition-transform" />
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="w-full mt-14 lg:mt-0 lg:w-1/3 lg:ml-24 relative">
          <div className="max-w-[375px] mx-auto xl:w-[375px]">
            <Image
              alt={content?.image_main?.alt || ''}
              src={content?.image_main?.filename}
              fit="contain"
              showPlaceholder={false}
              className={classNames('w-full')}
            />
          </div>
          <div className="xl:w-[197px] absolute bottom-16 -right-24 animate-float">
            <Image
              alt={content?.image_secondary?.alt || ''}
              src={content?.image_secondary?.filename}
              fit="contain"
              showPlaceholder={false}
              className={classNames('w-full')}
            />
          </div>
        </div>
      </Container>

      <div className="w-full lg:hidden h-80 absolute bottom-0">
        <Wave className="w-full absolute -top-80 md:-top-[600px] right-0 z-0 scale-x-[-1]" />
        <div className="bg-yellow-100 w-full h-80 lg:hidden absolute bottom-0" />
      </div>
    </div>
  );
};
