import { useState } from 'react';
import {
  Content,
  Description,
  Overlay,
  Portal,
  Root as DialogRoot,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';
import {
  Root as TooltipRoot,
  Trigger as TooltipTrigger,
  Content as TooltipContent,
  Arrow as TooltipArrow,
} from '@radix-ui/react-tooltip';
import { Controller, useForm } from 'react-hook-form';

import { NumericFormat } from 'react-number-format';
import { classNames, track } from '~/lib';
import { Button } from '../Button';
import { Form, NumberField, NumberInputField } from '../Forms';
import { Heading } from '../Heading';
import { ArrowRight, TooltipIcon } from '../Icons';
import { Spinner } from '../Spinner';
import { TrendingBg, TrendingBgMobile } from '../Vectors';

export const RoiCalculator = ({ content }) => {
  const [currentRevenue, setCurrentRevenue] = useState<number>(0);
  const [newRevenue, setNewRevenue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [calculated, setCalculated] = useState<boolean>(false);
  const [showCta, setShowCta] = useState<boolean>(false);

  const [aov, setAov] = useState<string>();
  const [visitors, setVisitors] = useState<string>();
  const [conversion, setConversion] = useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const fields: NumberField[] = [
    {
      name: 'visitors',
      label: content?.visitors_label || 'Yearly Visitors',
      required: true,
      placeholder: content?.visitors_placeholder || '1,000,000',
      fieldOptions: {
        allowLeadingZeros: true,
        decimalSeparator: '.',
        thousandSeparator: ',',
      },
    },
    {
      name: 'conversion',
      label: content?.conversion_label || 'Conversion Rate',
      required: true,
      placeholder: content?.conversion_placeholder || '3.3 %',
      fieldOptions: {
        step: '.01',
        suffix: ' %',
      },
    },
    {
      name: 'order_value',
      label: content?.order_label || 'Average order value',
      required: true,
      placeholder: content?.order_placeholder || '$ 100',
      fieldOptions: {
        prefix: '$ ',
        allowLeadingZeros: true,
        decimalSeparator: '.',
        thousandSeparator: ',',
      },
    },
  ];

  const onSubmit = (values) => {
    setLoading(true);

    track({ event: 'roi_calculator' });

    const timeout = setTimeout(() => {
      setLoading(false);

      return clearTimeout(timeout);
    }, 500);

    const ctaTimeout = setTimeout(() => {
      setShowCta(true);

      return clearTimeout(ctaTimeout);
    }, 3000);

    const visitors = parseInt(values?.visitors?.replace(/[^0-9.]/g, ''));
    const conversion =
      parseFloat(values?.conversion?.replace(/[^0-9.]/g, '')) / 100;
    const orderValue = parseFloat(values?.order_value?.replace(/[^0-9.]/g, ''));

    setCurrentRevenue(Math.round(visitors * orderValue * conversion));
    setNewRevenue(
      Math.round(visitors * 1.05 * orderValue * 1.1 * conversion * 1.15),
    );
    setCalculated(true);
    setVisitors(values?.visitors);
    setAov(values?.order_value);
    setConversion(values?.conversion);
  };

  return (
    <div
      className={classNames('w-full relative', content?.first_block && 'mt-20')}
    >
      <DialogRoot>
        <TrendingBg className="hidden md:block absolute top-0 right-0 w-screen z-0" />
        <TrendingBgMobile className="md:hidden absolute top-32 right-0 w-screen z-0" />
        <div className="w-full max-w-6xl px-4 mx-auto flex flex-col py-20 relative z-10">
          <div className="w-full md:max-w-2xl max-w-prose mx-auto md:mx-0">
            {!!content?.pretitle && (
              <Heading as="h6" variant="h6" className="mt-6 md:mb-2">
                {content?.pretitle}
              </Heading>
            )}
            {!!content?.title && (
              <Heading as="h2" variant="h2" className="!font-medium">
                {content?.title}
              </Heading>
            )}
            {!!content?.subtitle && (
              <p className="md:max-w-md text-sm md:text-lg mt-4 md:mt-5 text-gray-600">
                {content?.subtitle}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col lg:flex-row mt-8 lg:mt-16 space-y-8 lg:space-y-0 lg:space-x-12">
            <div className="w-full lg:max-w-md bg-yellow-50 border border-yellow-300 rounded-2xl p-5 lg:p-8 flex flex-col shadow-md">
              <div className="flex items-center pb-4 border-b">
                <span className="text-lg mr-2">
                  {content?.current_stats_title || 'Your current stats'}
                </span>

                {!!content?.tooltip_text && (
                  <TooltipRoot delayDuration={0}>
                    <TooltipTrigger>
                      <TooltipIcon className="w-4 text-gray-500" />
                    </TooltipTrigger>
                    <TooltipContent sideOffset={8}>
                      <div className="py-2 px-3 bg-gray-900 rounded-lg text-white text-xs shadow-md">
                        {content?.tooltip_text}
                      </div>
                      <TooltipArrow />
                    </TooltipContent>
                  </TooltipRoot>
                )}
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-6 space-y-6"
              >
                {fields?.map((field, i) => (
                  <Controller
                    key={i}
                    control={control}
                    name={field?.name}
                    render={({ field: { onChange, value } }) => (
                      <NumberInputField
                        field={field}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                ))}

                <Button
                  variant="secondary"
                  type="submit"
                  isLoading={isSubmitting}
                  className="w-full mt-10"
                >
                  {content?.calculate_button || 'Calculate'}
                </Button>
              </form>
            </div>

            <div className="flex-1 w-full bg-white border rounded-2xl p-5 lg:p-8 flex flex-col shadow-md">
              <p className="text-lg pb-4 border-b mb-6">
                {content?.growth_title || 'Your growth potential'}
              </p>
              <div className="flex-1 border p-4 lg:p-6 rounded-md mb-4">
                <div className="text-gray-700 text-sm">
                  {content?.current_label || 'Current annual revenue'}
                  {loading && (
                    <div className="w-full h-12 flex items-center justify-center">
                      <Spinner className="w-5 h-6 text-black" />
                    </div>
                  )}
                </div>
                <div className="text-3xl">
                  {!loading && (
                    <NumericFormat
                      value={currentRevenue}
                      prefix="$ "
                      displayType="text"
                      decimalSeparator={'.'}
                      thousandSeparator={','}
                    />
                  )}
                </div>
              </div>
              <div className="flex-1 border p-4 lg:p-6 rounded-md mb-4">
                <div className="text-gray-700 text-sm">
                  {content?.ic_investment_title ||
                    'Annual Instant Commerce investment'}
                  {loading && (
                    <div className="w-full h-12 flex items-center justify-center">
                      <Spinner className="w-5 h-6 text-black" />
                    </div>
                  )}
                </div>
                <div className="text-3xl">
                  {!loading && (
                    <NumericFormat
                      value={calculated ? content?.ic_investment_amount : 0}
                      prefix="$ "
                      displayType="text"
                      decimalSeparator={'.'}
                      thousandSeparator={','}
                    />
                  )}
                </div>
              </div>
              <div className="flex-1 border p-4 lg:p-6 rounded-md mb-8">
                <div className="text-gray-700">
                  <div className="flex justify-between text-sm">
                    {content?.new_label || 'New annual revenue'}
                    {newRevenue !== 0 && !loading && (
                      <div className="rounded-full bg-success-100 text-success-800 px-2 md:px-3 py-0.5 md:py-1 lg:hidden text-sm md:text-base">
                        <NumericFormat
                          prefix="+ $ "
                          value={newRevenue - currentRevenue}
                          displayType="text"
                          decimalSeparator={'.'}
                          thousandSeparator={','}
                        />
                      </div>
                    )}
                  </div>
                  {loading && (
                    <div className="w-full h-12 flex items-center justify-center">
                      <Spinner className="w-5 h-6 text-black" />
                    </div>
                  )}
                </div>
                <div className="flex items-baseline justify-between">
                  <div className="text-3xl">
                    {!loading && (
                      <NumericFormat
                        value={newRevenue}
                        displayType="text"
                        decimalSeparator={'.'}
                        thousandSeparator={','}
                        prefix="$ "
                      />
                    )}
                  </div>
                  {newRevenue !== 0 && !loading && (
                    <div className="rounded-full bg-success-100 text-success-800 px-3 py-1 hidden lg:block">
                      <NumericFormat
                        prefix="+ $ "
                        value={newRevenue - currentRevenue}
                        displayType="text"
                        decimalSeparator={'.'}
                        thousandSeparator={','}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              'max-w-4xl mx-auto border w-full border-yellow-300 p-5 rounded-lg mt-7 shadow-sm flex flex-col lg:flex-row items-center justify-center lg:justify-between text-sm text-center lg:text-left',
              'transition-all',
              showCta ? 'opacity-100' : 'opacity-0 translate-y-3',
            )}
          >
            <p>
              <span className="font-bold mr-3">{content?.cta_title}</span>
              {content?.cta_description}
            </p>
            <Trigger asChild>
              <button className="text-sm text-navy-500 mx-4 my-2 flex items-center group">
                {content?.cta_button}
                <ArrowRight className="w-3 ml-3 group-hover:transform group-hover:translate-x-2 transition-transform" />
              </button>
            </Trigger>
          </div>
        </div>

        <Portal>
          <Overlay className="fixed inset-0 overflow-y-auto z-50 bg-black/30">
            <div className="flex min-h-full items-center justify-center p-4">
              <Content className="mx-auto w-full max-w-sm md:max-w-lg bg-white px-5 py-6 sm:p-8 rounded-2xl relative">
                <Title className="text-2xl font-medium leading-tight">
                  {content?.form_title}
                </Title>
                <Description className="text-sm md:text-base text-gray-700 mt-3 md:mt-5">
                  {content?.form_subtitle}
                </Description>

                <Form
                  className="mt-6"
                  content={content}
                  extraFields={{
                    roi_calculator___aov: aov,
                    roi_calculator___avg_conversion_rate: conversion,
                    roi_calculator___yearly_visitors: visitors,
                  }}
                />
              </Content>
            </div>
          </Overlay>
        </Portal>
      </DialogRoot>
    </div>
  );
};
