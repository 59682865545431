import { Image } from '@storyofams/storyblok-toolkit';
import { getLinkProps } from '~/lib';
import { Heading, Link } from '..';
import { Email, LocationPin, Phone } from '../Icons';

interface LocationSectionProps {
  content?: any;
}

export const LocationSection = ({ content }: LocationSectionProps) => {
  const addressLines = content?.address?.split('\n');

  return (
    <div className="flex flex-col items-center max-w-6xl mx-auto px-5 pb-24">
      <Heading variant="h2" as="h2" className="text-center">
        {content?.title}
      </Heading>
      <div className="bg-yellow-50 rounded-3xl flex flex-col-reverse md:flex-row overflow-hidden w-full mt-12">
        <div className="md:w-1/2 p-8 md:p-16 flex flex-col">
          <Heading variant="h4" as="h4">
            {content?.location}
          </Heading>
          <div className="border-t w-full my-8" />
          <div className="flex flex-col justify-between flex-1">
            {!!content?.address && (
              <Link href={getLinkProps(content?.address_link)} className="flex">
                <LocationPin className="w-5 h-6 md:h-8" />
                <div className="lg:text-xl ml-4">
                  {addressLines?.map((line, i) => (
                    <div key={i} className="lg:text-xl">
                      {line}
                    </div>
                  ))}
                </div>
              </Link>
            )}
            <div>
              {!!content?.email && (
                <div className="flex mt-10 lg:mt-0">
                  <Email className="w-5 h-6 md:h-8" />
                  <a
                    href={`mailto:${content?.email}`}
                    target="_blank"
                    rel="noreferrer"
                    className="lg:text-xl ml-4"
                  >
                    {content?.email}
                  </a>
                </div>
              )}
              {!!content?.phone && (
                <div className="flex mt-5">
                  <Phone className="w-5 h-6 md:h-8" />
                  <a
                    href={`tel:${content?.phone}`}
                    target="_blank"
                    rel="noreferrer"
                    className="lg:text-xl ml-4"
                  >
                    {content?.phone}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-1/2 overflow-hidden relative">
          <Image
            alt={content?.photo?.alt || ''}
            src={content?.photo?.filename}
            fluid={556}
            fit="cover"
            showPlaceholder={false}
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};
