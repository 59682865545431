export const Email = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 16"
    >
      <path
        d="m.404 3.06 9.597 4.798 9.596-4.797A2.4 2.4 0 0 0 17.201.8H2.8A2.4 2.4 0 0 0 .404 3.06Z"
        fill="currentColor"
      />
      <path
        d="m19.6 5.742-9.6 4.8-9.6-4.8V12.8a2.4 2.4 0 0 0 2.4 2.4h14.4a2.4 2.4 0 0 0 2.4-2.4V5.742Z"
        fill="currentColor"
      />
    </svg>
  );
};
