import { Image } from '@storyofams/storyblok-toolkit';
import { classNames, getLinkProps } from '~/lib';
import { Heading } from '../Heading';
import { Link } from '../Link';

export const IntegrationsBlock = ({ content }) => {
  return (
    <div
      className={classNames(
        'bg-grey-50',
        content?.padding_top ? 'py-20' : 'pb-20',
      )}
      id={content?.id}
    >
      <div className="max-w-6xl px-4 md:px-5 mx-auto">
        {!!content?.title && (
          <Heading as="h2" variant="h4">
            {content?.title}
          </Heading>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4 md:gap-x-6 md:gap-y-8 mt-6 md:mt-10">
          {content?.integrations?.map((card) => (
            <Link
              key={card?._uid}
              href={getLinkProps(card?.link)}
              className="w-full bg-white px-6 pt-6 pb-8 rounded-xl hover:shadow-menu"
            >
              <div className="flex justify-between items-start mb-6 h-12">
                <div className="h-12 w-12">
                  {!!card?.logo?.filename && (
                    <Image
                      alt={card?.logo?.alt || ''}
                      src={card?.logo?.filename}
                      fluid={80}
                      fit="contain"
                      showPlaceholder={false}
                      height="100%"
                    />
                  )}
                </div>
                {!!card?.label && (
                  <div className="text-sm text-grey-700 bg-grey-50 rounded-2xl px-2.5 py-1">
                    {card?.label}
                  </div>
                )}
              </div>
              {!!card?.title && (
                <Heading as="h6" variant="h5">
                  {card?.title}
                </Heading>
              )}
              {!!card?.description && (
                <div className="text-grey-600 text-sm mt-2">
                  {card?.description}
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
