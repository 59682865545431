import { Image } from '@storyofams/storyblok-toolkit';
import { classNames, getLinkProps } from '~/lib';
import { Heading, Link, social } from '.';

interface TeamCardProps {
  person?: any;
}

export const TeamCard = ({ person }: TeamCardProps) => {
  return (
    <div className="w-full h-full border border-yellow-300 rounded-3xl bg-yellow-50 bg-opacity-50 bg-gradient-to-t from-yellow-50 p-10 flex flex-col items-center mt-6 first:mt-0 md:mt-0">
      <div className="w-24 h-24 rounded-full overflow-hidden">
        <Image
          alt={person?.content?.photo?.alt || ''}
          src={person?.content?.photo?.filename}
          fluid={person?.content?.photo?.size ? 100 : 100}
          fit="contain"
          showPlaceholder={false}
          className={classNames('w-full max-w-100 rounded-full')}
        />
      </div>
      <Heading variant="h5" as="h5" className="mt-8 text-center">
        {person?.content?.name}
      </Heading>
      <div className="mt-2 text-center">{person?.content?.position}</div>
      <div className="flex mt-8">
        {social.map((item) => {
          if (!person?.content?.[item.key]) {
            return null;
          }

          return (
            <Link
              key={item.key}
              href={getLinkProps(person?.content?.[item.key])}
              className="text-gray-900 hover:text-gray-700 ml-2 first:ml-0 bg-white rounded px-6 py-3"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-5 w-5" aria-hidden="true" />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
