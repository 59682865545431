import { ReactNode } from 'react';
import { Image } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';
import { Heading } from '../Heading';

type FormContainerProps = {
  content?: any;
  className?: string;
  children: ReactNode | string;
};

export const FormContainer = ({
  content,
  className,
  children,
}: FormContainerProps) => {
  return (
    <div
      id={content?.id}
      className={classNames(
        'pb-20 lg:pb-24 relative',
        'pt-28 md:pt-24',
        'bg-yellow-50',
        className,
      )}
    >
      <div
        className={classNames(
          'flex flex-col items-start lg:flex-row relative',
          'max-w-6xl px-4 md:px-5 mx-auto',
        )}
      >
        <div className={classNames('w-full mx-auto', 'max-w-[480px]')}>
          <Heading as="h6" variant="h6" className="text-gray-600">
            {content?.kicker}
          </Heading>
          <Heading
            as="h3"
            variant="h3"
            className="text-gray-800 sm:font-medium mt-2"
          >
            {content?.title}
          </Heading>
          <p className="text-gray-600 mt-4">{content?.subtext}</p>
          <div className="w-full mt-10">{children}</div>
        </div>

        <div className="w-full max-w-2xl lg:max-w-none lg:w-7/12 bg-yellow-50 mt-12 lg:mt-0 mx-auto lg:mr-0 lg:ml-20">
          <div className={classNames('hidden lg:block lg:max-w-[556px]')}>
            {!!content?.image?.filename && (
              <Image
                alt={content?.image?.alt || ''}
                src={content?.image?.filename}
                fit="contain"
                showPlaceholder={false}
                className={classNames('w-full object-center', 'rounded-lg')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
