import { classNames } from '~/lib';
import { Heading, QuestionList } from '..';

interface FaqProps {
  kicker?: string;
  title?: string;
  questions?: any;
  className?: string;
  darkMode?: boolean;
  component?: string;
}

export const Faq = ({
  kicker,
  title,
  questions,
  className,
  darkMode,
  component,
}: FaqProps) => {
  return (
    <div
      className={classNames(
        component === 'page' && 'max-w-6xl mx-auto px-4 lg:px-5 pb-24',
      )}
    >
      <div
        className={classNames(
          'md:border md:rounded-2xl flex flex-col items-center md:py-20 md:px-28',
          darkMode && 'border-grey-400',
          className,
        )}
      >
        <Heading
          as="h6"
          variant="h6"
          className={classNames('font-sans mb-2', darkMode && 'text-white')}
        >
          {kicker}
        </Heading>
        <Heading
          as="h3"
          variant="h3"
          className={classNames('mb-2 text-center', darkMode && 'text-white')}
        >
          {title}
        </Heading>
        <QuestionList content={questions} darkMode={darkMode} />
      </div>
    </div>
  );
};
