export const Wave = ({
  className,
  opacity = '.5',
}: {
  className?: string;
  opacity?: string;
}) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1040 909"
  >
    <path
      opacity={opacity}
      d="M-1091.05 186.832c-324.15-14.547-405.18 225.137-438.95 332.509V909h3057.09c0-119.495 93.33-694.978-361.04-866.774C598.099-172.52 522.776 499.425-104.918 544.452-732.612 589.48-685.86 205.016-1091.05 186.832Z"
      fill="#ffff"
    />
  </svg>
);
