import dynamic from 'next/dynamic';
import SbEditable from 'storyblok-react';
import { HeaderCta } from '../HeaderCta';

import { CardsSection } from '../Home';
import { BlockDynamic } from './BlockDynamic';
import { CardSwiper } from './CardSwiper';
import { CasesSwiper } from './CasesSwiper';
import { ContactForm } from './ContactForm';
import { ContentAsset } from './ContentAsset';
import { ContentBg } from './ContentBg';
import { CtaSection } from './CtaSection';
import { DynamicForm } from './DynamicForm';
import { Faq } from './Faq';
import { Features } from './Features';
import { FeatureSection } from './FeatureSection';
import { FeaturesForm } from './FeaturesForm';
import { FeaturesLanding } from './FeaturesLanding';
import { Header } from './Header';
import { HeaderImage } from './HeaderImage';
import { HeaderSimple } from './HeaderSimple';
import { IntegrationsBlock } from './IntegrationsBlock';
import { IntegrationsSection } from './IntegrationsSection';
import { InvestorsSection } from './InvestorsSection';
import { LocationSection } from './LocationSection';
import { PlanComparisonSection } from './PlanComparisonSection';
import { PricingSection } from './PricingSection';
import { QuoteSection } from './QuoteSection';
import { RoiCalculator } from './RoiCalculator';
import { ScrollSection } from './ScrollSection';
import { StatisticsContentSection } from './StatisticsContentSection';
import { TeamSection } from './TeamSection';
import { TestimonialSection } from './TestimonialSection';

const LogoSection = dynamic(() => import('./LogoSection'), {
  ssr: false,
});

const MetricsSection = dynamic(() => import('./MetricsSection'), {
  ssr: false,
});

const DataVisualisation = dynamic(
  () => import('~/components/Home/DataVisualisation'),
  { ssr: false },
);

type BlockProps = {
  className?: string;
  content: any;
  first?: boolean;
  title?: string;
  sectionType: string;
  component?: string;
  tableOfContents?: {
    getContent: 'auto' | 'manual';
    manualContent: any[];
  };
};

export const Block = ({
  content,
  first,
  sectionType,
  component,
  title,
  className,
  tableOfContents,
}: BlockProps) => {
  let section = null;
  const shared = { content, first };

  switch (sectionType) {
    case 'contact_form':
      section = <ContactForm />;
      break;
    case 'form_features':
      section = <FeaturesForm content={content} />;
      break;
    case 'card_swiper':
      section = <CardSwiper content={content} className="!py-20" />;
      break;
    case 'cards_section':
      section = <CardsSection content={content} className="py-20" />;
      break;
    case 'cases_swiper':
      section = <CasesSwiper content={content} />;
      break;
    case 'content_asset':
      section = <ContentAsset content={content} />;
      break;
    case 'content_bg':
      section = <ContentBg content={content} />;
      break;
    case 'cta_section':
      section = <CtaSection content={content} />;
      break;
    case 'data_visualisation':
      section = <DataVisualisation content={content} className="py-20 my-20" />;
      break;
    case 'form':
      section = <DynamicForm content={content} />;
      break;
    case 'header':
      section = <Header {...shared} />;
      break;
    case 'header_cta':
      section = (
        <HeaderCta
          content={content}
          className="pt-16 md:pt-32 mb-16 md:mb-20"
        />
      );
      break;
    case 'header_simple':
      section = <HeaderSimple {...shared} />;
      break;
    case 'header_image':
      section = <HeaderImage content={content} />;
      break;
    case 'integrations':
      section = <IntegrationsBlock content={content} />;
      break;
    case 'team_section':
      section = <TeamSection content={content} />;
      break;
    case 'feature_card':
      section = <FeatureSection section={content} />;
      break;
    case 'features_section':
      section = <Features content={content} />;
      break;
    case 'features_landing':
      section = <FeaturesLanding content={content} />;
      break;
    case 'scroll_section':
      section = <ScrollSection section={content} />;
      break;
    case 'logo_section':
      section = <LogoSection content={content} component={component} />;
      break;
    case 'statistics_content_section':
      section = <StatisticsContentSection content={content} />;
      break;
    case 'location_section':
      section = <LocationSection content={content} />;
      break;
    case 'investors_section':
      section = <InvestorsSection content={content} />;
      break;
    case 'integrations_section':
      section = <IntegrationsSection section={content} />;
      break;
    case 'faq_section':
      section = (
        <Faq
          title={content?.title}
          kicker={content?.kicker}
          questions={content?.questions}
          component={component}
        />
      );
      break;
    case 'metrics_section':
      section = <MetricsSection content={content} />;
      break;
    case 'plan_comparison':
      section = <PlanComparisonSection content={content} />;
      break;
    case 'pricing_section':
      section = <PricingSection content={content} />;
      break;
    case 'quote_section':
      section = <QuoteSection content={content} />;
      break;
    case 'roi_calculator':
      section = <RoiCalculator content={content} />;
      break;
    case 'testimonial':
      section = <TestimonialSection content={content} />;
      break;
    case 'section':
      section = (
        <BlockDynamic
          title={title}
          {...shared}
          className={className}
          component={component}
          tableOfContents={tableOfContents}
        />
      );
  }

  return <SbEditable content={content}>{section}</SbEditable>;
};
