import { Image } from '@storyofams/storyblok-toolkit';
import { classNames, getLinkProps } from '~/lib';
import { Heading } from '..';
import { Button } from '../Button';

interface IntegrationsSectionProps {
  section?: any;
  className?: string;
}

export const IntegrationsSection = ({
  section,
  className,
}: IntegrationsSectionProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center pb-28 relative max-w-6xl px-4 mx-auto',
        className,
      )}
    >
      <div className="max-w-3xl">
        <Heading
          variant="h5"
          as="h5"
          className="text-xl text-yellow-600 text-center"
        >
          {section?.kicker}
        </Heading>
        <Heading variant="h3" as="h3" className="mt-4 text-center">
          {section?.title}
        </Heading>
        <div className="text-grey-500 text-xl text-center mt-6">
          {section?.subtext}
        </div>
      </div>
      <div className="max-w-2xl mt-14 flex flex-wrap justify-center">
        {section?.logos?.map((logo) => (
          <div className="w-12 lg:w-20 m-4 md:m-8" key={logo?._uid}>
            <Image
              alt={logo?.image?.alt || ''}
              src={logo?.image?.filename}
              fit="contain"
              showPlaceholder={false}
              className={classNames('')}
            />
          </div>
        ))}
      </div>
      {!!section?.button_text && (
        <Button
          variant="outline"
          size="large"
          className="mt-12 md:mt-16"
          href={getLinkProps(section?.button_link)}
        >
          {section?.button_text}
        </Button>
      )}
    </div>
  );
};
